<template>
  <div class="misc-wrapper">
    <b-link
      to="/"
      class="brand-logo"
    >
      <vuexy-logo />
      <h3 class="brand-text ml-1">
        BEESUMMER 夏蜂
      </h3>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100">
        <b-card>
          <Privacy />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard,
  BLink,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Privacy from './terms/PrivacyData.vue'

export default {
  components: {
    BCard,
    BLink,
    Privacy,
    VuexyLogo,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
$misc-inner-max-width: 70%;

.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  .misc-inner {
    position: relative;
    max-width: $misc-inner-max-width;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;
    .brand-text {
      font-weight: 600;
    }
  }
}
.brand-logo {
  svg {
    height: 28px;
    width: 40.95px;
  }
}
</style>
